const Ticket = () => {
    return ( 
        <section id="tickets" className="container-fluid ticket-section">
        <div className="container">
          <h2 className="section-title">Reda2023 Tickets</h2>
          <p>Whether you are looking for long-term strategies or for the future,
            the ideas and visions of the speakers will inspire you and help you develop
            concrete measures to thrive in the real estate industry.</p>
          <div className="ticket-group">

            <div className="ticket-item">
              <div className="ticket-head">
                <h3 className="regular">&#8358;50,000</h3>
              </div>
              <div className="ticket-body">
                <p>X1 Full Access to all sessions</p>
                <p>Access to networking events</p>
              </div>
              <div className='ticket-button'>
              <a href="https://reda2022.thinkmint.eu/ticket/product/regular-ticket/">Buy Regular Ticket</a>
              </div>
            </div>

            <div className="ticket-item">
              <div className="ticket-head">
                <h3 className="standard">&#8358;100,000</h3>
              </div>
              <div className="ticket-body">
              <p>X1 Full Access to all Sessions</p>
              <p>Access to networking events</p>
              <p>Certificate of attendance</p>
              <p>Access to award ceremony</p>
              </div>
              <div className='ticket-button'>
              <a href="https://reda2022.thinkmint.eu/ticket/product/standard-ticket/">Buy Standard Ticket</a>
              </div>
            </div>

            <div className="ticket-item">
              <div className="ticket-head">
                <h3 className="vip">&#8358;120,000</h3>
              </div>
              <div className="ticket-body">
                <p> X1 Full Access to all Sessions</p>
                <p>Access to networking events</p>
                <p>Certificate of attendance</p>
                <p>Access to award ceremony</p>
                <p>Company profile &#38; logo on event brochure and media communications</p>
              </div>
              <div className='ticket-button'>
              <a href="https://reda2022.thinkmint.eu/ticket/product/vip-ticket/">Buy VIP Ticket</a>
              </div>
            </div>

            <div className="ticket-item">
              <div className="ticket-head">
                <h3 className="six">&#8358;500,000</h3>
              </div>
              <div className="ticket-body">
                <p>X6 Full Access to all sessions</p>
                <p>Access to networking events</p>
                <p>Certificate of attendance</p>
                <p>Access to award ceremony</p>
                <p>Company profile &#38; logo on event brochure</p>
                <p>Display of company branded items</p>
              </div>
              <div className='ticket-button'>
              <a href="https://reda2022.thinkmint.eu/ticket/product/table-of-6/">Buy Table for Six Ticket</a>
              </div>
            </div>

          </div>
        </div>
      </section>
     );
}
 
export default Ticket;