import thinkmintlogo from '../images/tmlogos.png'
import MarketPlace from './MarketPlace'
import RepsBanner from './RepsBanner'
import VoteBanner from './VoteBanner'

function Header() {
    return (
    <header className="container-fluid">
      <div className="container">
        <div className="thinkmint-logos">
          <img src={ thinkmintlogo } alt="Thinkmint group logos" className='thinkmint-logos'/>
        </div>
        <div className="header-text">
          <h1 className='header-event-name'>REDA23</h1>
          <h2 className='header-event-theme'>New Reality, New Collaborations</h2>
          <h3 className='header-event-date'>Date: 26th and 27th September, 2023 </h3>
          <h3 className='header-event-time'>Time: 10:00AM Daily</h3>
          <h3 className='header-event-venue'>Venue: Eko Hotel and Suites, Victoria Island, Lagos</h3>
        </div>
        <div className="header-buttons">
          <a href="https://mailchi.mp/thinkmint/reda2023" className='header-button-getaccess'>Get Exhibition Access</a>
          <a href="/#forms" className='header-button-register'>Register to Attend</a>
          <a href="https://www.reda2022.thinkmint.eu/" className='header-button-visit'>Visit REDA 2022</a>
          <a href="https://drive.google.com/file/d/1AXUmhBRq6jziGM_sl41wTn0dVEB7trgq/view?usp=sharing" className='header-button-brochure'> Sponsorship/Exhibition Brochure</a>
          <a href="https://www.reps2023.thinkmint.ng/" className='reps-visit'>Attend REPS</a>
        </div>
        <RepsBanner />
      </div>
    </header>

    )
}
export default Header