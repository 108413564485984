import { BrowserRouter } from "react-router-dom";
// import NavBar from "./components/Navbar";
import NavBar from "./components/Nav";
import Header from "./components/Header";
import RepsBanner from "./components/RepsBanner";
import VoteBanner from "./components/VoteBanner";
import OrganisingPartner from "./components/OrganisingPartner";
import Video from "./components/Video";
import Subtheme from "./components/Subtheme";
import SpeakerList from "./components/SpeakerList";
import Topics from "./components/Topics";
import Agenda from "./components/Agenda";
import Ticket from "./components/Ticket";
import Form from "./components/Form";
import Footer from "./components/Footer";


function App() {
  return (
    <BrowserRouter>
      <div className="App">
      <NavBar />
      <Header />
      <VoteBanner />
      <OrganisingPartner />
      <Video />
      <Subtheme />
      <SpeakerList />
      <VoteBanner />
      <Topics />
      <Agenda />
      <RepsBanner />
      <Form />
      <Ticket />
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
