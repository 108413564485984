import Table from 'react-bootstrap/Table';

const Agenda = () => {
    const colTime = 1
    const colNum = 2
    
    return (
        <section className="container-fluid agenda-bg">
        <div className="agenda container">
            <h2 className="section-title">REDA2023 Agenda</h2>
            <div className='day-1'>
                <h2 className='table-title'>Day 1 - Tuesday, September 26th, 2023</h2>
                <Table hover>
                <thead>
                    <tr>
                    <th colSpan={colTime}>Time</th>
                    <th colSpan={colNum}>Activities</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        8:00 AM - 10:00 AM
                        </div>
                        </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Registration / Guest Arrival
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        10:00 AM - 11:00 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                       Private Investors Forum (Strictly by Invitation)
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                    <div className='table-time'>
                    11:00 AM - 11:20 AM
                    </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Welcome Address:  Imelda Usoro Olaoye (Managing Partner, Thinkmint Nigeria)
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        11:20 AM - 11:35 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Opening Speech:  Hon Ayodeji Joseph (MD/CEO LSDPC)
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        11:20 AM - 11:35 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Keynote Speech by SPECIAL GUEST OF HONOUR : His Excellency Rt Hon Francis Nwifuru, The Executive Governor of Ebonyi State
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={2}>
                        <div className='table-title'>
                        Planery 1
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        11:50 AM - 12:00 PM
                        </div>
                        
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Sponsor Presentation
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        12:00 PM – 12:45 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Small world, Big Opportunities - Real estate investment and the global marketplace 
                        </div>

                        <div className='table-content'>
                        <p>
                        The real estate market has evolved significantly over the past 10 years and has experienced rapid growth throughout the world in its various forms. Many emerging countries witnessed the significant growth in their commercial real estate markets that became a stable sector of their economies. These countries, after developing a reliable commercial real estate base within their economies subsequently developed real estate financial markets. The growth of the real estate investment trusts, REITs, markets in many countries within the past decade helped attract global capital that facilitated additional investments in local real estate developments. Significantly, this period of time may have witnessed a higher degree of integration of real estate with the broader financial markets due in large part to the securitization of mortgages. Yet the general real estate market was also impacted in many parts of the world with rising prices and subsequent price collapses. This session focuses on the various areas of the global real estate market and the changes that it has encountered vis-a-vis the Nigerian Real estate market as examined by experts of the industry.
                        </p>
                        <p><span className='table-title'>Moderator:</span> Deborah Nicol-Omeruah(Board Member/Independent Director YPO/RICS)</p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Mark Okoye (MD/CEO, ANSIPPA)</li>
                        <li>Abiodun Mamora (Executive Director Finance Construction Kaiser)</li>
                        </ul>
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        12:45 PM - 1:20 PM
                         </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Exhibition Opening/Tour Led by Special Guest of Honour, Guest Speaker, Keynote speaker 
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        1:20 PM - 2:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        NETWORKING BREAK
                        </div>
                    </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                        <div className='table-title'>Plenary 2</div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        2:15 PM -  3:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Women in real estate - Rising Global market leaders
                        </div>
                        <div className='table-content'>
                        <p>
                        Women have been part of the real estate industry since the beginning, often working in more administrative roles, but starting in the early 1900s, more and more ladies began to become brokers, agents and developers. Today, successful women in real estate represent a large number of the industry and many are advancing to leadership positions across the nation.
                        Real estate offers women the opportunity for economic empowerment and career advancement. This session will discuss the current trends that showcase the impact women have on the industry, room to improve and trends to watch out for in the future of females in the real estate industry.
                        </p>
                        <p><span className='table-title'>Moderator:</span> Ayekua Ampah (Founder/ CEO of Ayekua Realty Ltd)</p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Grace Ofure (CEO/Lifecard Investment Limited)</li>
                        <li>Tosin Ajose (LEAD ADVISOR , DEAL HQ PARTNERS)</li>
                        <li>Monsurat Mohammed  (Head marketing and relationship management, FHA Mortgage bank ltd)</li>
                        <li>Uzo Oshogwe (MD/CEO, Afriland properties plc)</li>
                        
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                        <div className='table-title'>Plenary 3</div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        3:00 PM - 3:15 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        SPONSOR PRESENTATION - HOMEWORK GROUP </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        3:15 PM - 4:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Leading the way to a more diverse , equitable and inclusive future for real estate-Africa in focus
                        </div>
                        <div className='table-content'>
                        <p>
                        Nigeria has an acute housing deficit estimated to be in the region of 22 million, which would require amounts running into trillions to fix. Besides, to properly tackle the accommodation shortage, the nation will need to build one million housing units per year in the next 20 years to bridge the gap in housing needs. 
                        </p>
                        <p>
                            Given the sheer unmet demand, Nigeria presents the largest, multi-billion affordable housing opportunity in the world. Despite the efforts of several developers, you would assume that there ought to be a stampede of investors, established corporate houses, and some of the country’s canniest entrepreneurs to grab the proverbial pot of gold at the end of the rainbow. Yet surprisingly, the situation seems to linger with increased demand for housing units year in year out with the housing conundrum probably something unheard of.
                        </p>
                        <p>
                            This session  will discuss the several hypotheses on the state of play, as well as solutions or suggestions to these hypotheses and their cost implications.
                        </p>
                        <p><span className='table-title'>Moderator:</span> Obinna Udeagha (Director, Property & Facilities Management)</p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Jide Adekola (Group Managing Director,HOMEWORK GROUP AFRICA)</li>
                        <li>Simon Sondern (Founder, Vibe Real Estate)</li>
                        <li>Chukwuma Harrison Okoye (CEO , Facibus Housing)</li>
                        </ul>

                        </div>
                    </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                        <div className='table-title'>Plenary 4</div>
                        </td>
                    </tr>


                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        4:00 PM - 4:15 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        SPONSOR PRESENTATION
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        4:15 PM - 5:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        CHALLENGES AND OPPORTUNITIES IN DIVERSITY - PROPTECH IN FOCUS
                        </div>
                        <div className='table-content'>
                        <p>
                        Real estate as an essential asset and as an industry is not immune to the innovations made possible by the "fourth industrial revolution". What has become widely known as PropTech describes the digital transformation that is currently taking place within the real estate industry and positioned to drive a change regarding technology -driven innovations in data assembly,  transactions and the designs of buildings and cities.
                        </p>

                        <h3 className='table-title'>This Session discusses</h3>
                        <ul>
                            <li>How Africa is harnessing the usage of PropTech in the areas of smart buildings,  Real estate finance/Real Estate Fin-Tech , shared economy and financial technology.
                            </li>
                            <li>
                            The verticals that facilitate information for better transactions and management offered through the industry's horizontals of Fintech , shared economy and smart real estate.
                            </li>
                            <li>
                            The challenges and opportunities available for technology builders, drivers and startups in the real estate industry.
                            </li>
                        </ul>
                        <p><span className='table-title'>Moderator:</span> Naomi Olaghere (Co-founder/VP Operations , Small Small)</p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Sean Godoy (MD/Co-founder, PropTech Africa, CEO Divercity Group)</li>
                        <li>Tunde Balogun (CEO, Rent Small Small)</li>
                        <li>Muyideen Dosunmu (Co-Founder & CEO, GatePass)</li>
                        <li>Akintola Adesanmi (Co-Founder and CEO, Spleet)</li>
                        <li>Bukunmi Ajiboye (Co-founder, vampfi)</li>
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        5:00 PM - 5:45 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        EXHIBITION TOUR 
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        5:45 PM - 6:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        CLOSING REMARKS 
                        </div>
                    </td>
                    </tr>
                </tbody>
                </Table>
            </div>

            {/* day 2 */}

            <div className='day-2'>
            <h2 className='table-title'>Day 2 - Wednesday, September 27th, 2023</h2>

            <Table hover>
                <thead>
                    <tr>
                    <th colSpan={colTime}>Time</th>
                    <th colSpan={colNum}>Activities</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        9:00 AM - 10:00 AM
                        </div>
                        </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Registration / Breakfast
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        10:00 AM - 10:15 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        KEYNOTE SPEECH BY KEYNOTE SPEAKER DAY 2
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        10:15 AM - 10:30 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        SPONSOR PRESENTATION 
                        </div>
                    </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                            <div className='table-title'>
                            Plenary 1
                            </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                    <div className='table-time'>
                    10:30 AM – 11:15 AM
                    </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Climate change disaster risks, spatial planning in real estate development and markets
                        </div>
                        <div className='table-content'>
                        <p>The growing trend of ESG integration amongst companies and investors is more than a decision about doing what is socially responsible or morally right. Growing evidence suggests that ESG integration may be a key differentiator, providing organisations with a competitive advantage and leading to improved long-term financial performance compared to its peers.  Over 80% of corporations now publicly report their performance against environmental, social and governance standards. </p>
                        <p>
                        Global Organisations such as The Sustainability Accounting Standards Board (SASB) provide guidance around determining financial materiality for companies across a wide variety of industries. The real estate industry has been seen to be focused on material environmental issues. 
                        </p>
                        <p>Considering investor demand for more sustainable real estate commodities and environmental performance,  what factors can help real estate companies attract lucrative asset owners & tenants, optimise operational performance, identify and mitigate risks and gain competitive advantage in the industry?</p>
                        <p>This session will discuss these and factors that have impacted the real estate markets at the global scale in terms of climate change and spatial planning.</p>

                        <p><span className='table-title'>Moderator:</span> Tilda Mwai (Research Associate, Estate Intel) </p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Greg Truen (Director/Professional Architect, SAOTA)</li>
                        <li>Nana Maidugu (Head of Sustainability and ESG , NSIA)</li>
                        <li>Onome Umukoro (Group Head , Marketing and Corporate Communication, Mixta Africa)</li>
                        <li>Chima Emerueh (Executive Director, HomeWork Group Africa)</li>
                        </ul>

                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        11: 15 AM - 12: 00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Retail, Office Space and Industrial Real Estate Where is the market heading?
                        </div>
                        <div className='table-content'>
                        <p>
                        No other real estate property category has attracted as much chatter in recent years as retail. Once a favourite of investors, this asset class is now facing many increased challenges. We have all watched African retailers opening stores, closing them or looking for fresh approaches to their formats. While there is a range of views on how online shopping is impacting the African retail market, it is clear that the landscape is shifting. This session will open with two presentations that will provide some informative insights on the emerging strategies of retailers and landlords. What are they doing in this highly competitive market to retain the vitality of personal shopping experiences? Some analysts are offering dire forecasts for the future of all mall formats as changing demographics and buying habits negatively affect poorer quality properties. This session will examine these and other trends, and discuss the facts and fallacies pertaining to where the African retail real estate market is heading from an investor, developer, retailer and consumer perspective.  
                        </p>

                        <p>
                        A strong case is made that industrial real estate has been on an accelerated and significant comeback trail over this decade. Industrial space vacancy across the country has fallen to a historically low level.  Supply tightened in major commercial real estate markets. 
                        </p>
                        <p>Africa’s office market has experienced a decline regionally in vacancy. What were the reasons for this trend when you consider that our cities are witnessing the largest surge in new development over the years? Is the demand from the high-tech market a key factor? To what extent is the strong growth and shift towards co-working office environments another reason? The office is becoming more than a conventional workspace. The result is a range of coworking business solutions offered by a number of players and the evolution of WorkPlace-as-a-Service (WaaS). This session will examine these and other trends including the demand for this space, its amenitization,  key investment, leasing and development trends in this asset class across Africa today.  </p>
                        <ul>
                            <li>What are the factors responsible for this exceptionally strong demand?</li>
                            <li>To what extent can it still be categorised as a tale of two submarkets comprising new modern warehousing buildings vs. older vintage properties with marginal applications for various users?</li>
                            <li>With so many of these asset classes under development, is demand so strong that there is justification for even more projects?</li>
                            <li>How strong is investor interest overall in the African industrial market?</li>
                            <li>What are  the facts and fallacies pertaining to where the African retail real estate market is heading from an investor, developer, retailer and consumer perspective. </li>
                            <li>What are the implications for leasing, and how to engage the appraisal and capital markets to properly assign value to flexible leases.</li>
                            <li>Can you “pick the lock on WaaS” including its demand, business, and balance with traditional leasing practices in today’s office market?</li>
                        </ul>
                        <p><span className='table-title'>Moderator:</span> Dolapo Omidire (Founder, Estate Intel) </p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Paul Onwuanibe (MD/CEO , Landmark Group)</li>
                        <li>Bernice Swanepoel (Director Sub-saharan Africa & India Ocean, Aire Consulting/AI Real Estate)</li>
                        <li>Adegbenga .A Fashola RICS (MD/CEO Square Meters)</li>
                        <li>Bldr. David Ololade Osungbohun (MD/CEO Annuva Homes)</li>
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={2}>
                    <div className='table-title'>
                        Plenary 2
                    </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        12:00 PM - 12:30 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        EXHIBITION TOUR - LED BY KEYNOTE SPEAKER
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        12:35 PM - 01:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Networking and Lunch
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        1:00 PM - 1:15 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Sponsor Presentation
                        </div>
                    </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                        <div className='table-title'>Plenary 3</div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        1:15 PM - 2:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Land and property management, administration policy and sustainability
                        </div>
                        <div className='table-content'>
                        <p>
                        In recent times, there has been the drive for the need to develop policy guidelines and recommendations to address major challenges in housing, urban development, land administration and management across the globe. These policy guidelines and recommendations are in the areas of housing affordability, social housing, low-cost housing and housing finance, improving building energy efficiency; facilitating the maintenance, management and refurbishment of existing housing stock; developing real estate markets; promoting integrated approaches to planning and building smart sustainable cities and urban settlements, smart sustainable cities financing; as well as land registration, e-governance, land tenure, spatial information and cadastres.
                        </p>
                        <p>
                        This session will discuss guidelines for establishing more efficient legal and institutional frameworks for housing, urban development, land management policy and advocate coordination of efforts to address major housing and urban development challenges in the region focusing on the areas below, especially in Nigeria:   
                        </p>
                        <ul>
                            <li>Challenges and Priorities in Housing and Land Management </li>
                            <li>Guidance and Good Practice for the Application of Fees and Charges for Real Property and Projects  </li>
                            <li>Ways to Enhance innovation and participation in smart sustainable cities/real estate projects  </li>
                            <li>The relationship between the Nigerian population and housing for the 21st century </li>
                            <li>Survey on Land administration systems in Lagos, vis-a-vis other states.</li>
                        </ul>

                        <p><span className='table-title'>Moderator:</span>  Adaku Chibuike-Ochiuwa (Senior Partner, Priceless Chamber) </p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Ayodeji Odeleye (Managing Director, NIESV)</li>
                        <li>Hon. Ayodeji Joseph (MD/CEO , LSDPC)</li>
                        <li>Odunayo Ojo (CEO , UPDC PLC)</li>
                        </ul>
                        
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        2:00 PM - 5:00 PM
                        </div>
                    </td>

                    <td>
                    <div className='table-title'>
                       Exhibition
                    </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        5:00 PM - 6:00 PM 
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Award Ceremony Dinner / Induction into REDA Hall of Fame
                        </div>
                    </td>
                    </tr>

                </tbody>
                </Table>





            </div>
         </div>
        </section> 
     );
}
 
export default Agenda;