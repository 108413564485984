import { Link } from 'react-router-dom';
import votingbanner from '../images/votingbanner.jpg'

const VoteBanner = () => {
    return (
        <Link to='https://forms.gle/uXn423qnrusiqyqx5' className="vote-banner">
            <img src={votingbanner} alt="" className="img-fluid" />
        </Link>
      );
}
 
export default VoteBanner;