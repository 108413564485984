import React, {useState, useRef} from "react";

const Form = () => {
    const formRef = useRef(null)
    const scriptURL = 'https://script.google.com/macros/s/AKfycbxI1oQU43FO1mSNTLtPMlG3osYVeM-BeQgmujkVhSiKn2gvR40OsU3kBhNmrK3WpMMk0w/exec'
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [formClass, setFormClass] = useState('')


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        fetch(scriptURL, {
            method: 'POST',
            body: new FormData(formRef.current),
        }).then(res => {
            setMessage('Registration Successful!')
            setFormClass('success-message')

            setTimeout(() => {
                setFormClass('')
                setMessage('')
                }, 5000);

            setLoading(false)
            formRef.current.reset()
        })
    }

    return ( 
        <section className="form-section  container-fluid"  id="forms">
            <div className="container">
                <h2 className="section-title text-light">
                    Register to Attend
                </h2>
                <form name="submit-to-google-sheet" ref={ formRef } onSubmit={handleSubmit}>
                    <div className="form-input-group">
                        <input type="text" placeholder="First Name" name="firstname" className="form-firstname" required />
                        <input type="text" placeholder="Last Name" name="lastname" className="form-lastname" required />
                        <input type="email" placeholder="Email Address" name="email" className="form-email" required />
                        <input type="tel" placeholder="Phone Number" name="phone" className="form-phone" required />
                        <input type="text" placeholder="Company Name" name="company" className="form-company" required />
                        <input type="text" placeholder="Position in Company" name="position" className="form-position" required />
                    </div>
                    <input type="submit" className="form-submit" value={loading ? "Registering..." : "Register"} />
                </form>
                <p className={ formClass }> { message } </p>
            </div>
        </section>
     );
}
 
export default Form;