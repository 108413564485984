const Topics = () => {
    return ( 
        <div className="container topic-section mycontainer" id="topics">
            <h2 className="section-title">Reda2023 Topics</h2>
            <div className="topic-group">
                <div className="topic-item"> Small world, Big Opportunites - Real estate investment and the global market place </div>
                <div className="topic-item"> Women in real estate - Rising Global market leaders </div>
                <div className="topic-item"> Challenges and Opportunities in Diversity in PropTech </div>
                <div className="topic-item"> Land and property management, administration policy and sustainability </div>
                <div className="topic-item"> Retail, Office Space and Industrial Real Estate Where is the market heading? </div>
                <div className="topic-item"> The market for extended stay accommodation - Deconstructing the winning formula for Short-lets and aparthotels </div>
                <div className="topic-item"> Climate change disaster risks, spatial planning in real estate development and markets </div>
            </div>
        </div>
     );
}
 
export default Topics;