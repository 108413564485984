import React, { useEffect, useState } from 'react';
import SpeakerCard from './SpeakerCard';
import speakersData from '../data/index.js';


const SpeakerList = ({ data }) => {
    const [speakers, setSpeakers] = useState([]);

    useEffect(() => {
      if (speakersData.length > 0) {
      setSpeakers(speakersData);
      }
    }, []);


    return (
        <section id="speakers" className = "container-fluid speaker-bg">
             <div className="container mycontainer">
             <h2 className="section-title">reda2023 speakers</h2>
             <div className='speaker-group'>
            {speakers.map((speaker) => (
   
                <SpeakerCard
                key={speaker.id}
                name={speaker.name}
                title={speaker.title}
                position={speaker.position}
                company={speaker.company}
                imageUrl={speaker.imageUrl}
                about={speaker.about}
                />
            ))}
            </div>
            </div>
          </section>
    );
}
 
export default SpeakerList;