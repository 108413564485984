import lsdpc  from '../images/lsdpc-logo.png';
import mixta  from '../images/mixta-logo.png';
import homework  from '../images/homework-logo2.png';
import dhq  from '../images/dhq-logo.png';
import fha  from '../images/fha-logo.png';
import market  from '../images/marketplace.png';
import fh  from '../images/fh-logo.png';


const OrganisingPartner = () => {
    return ( 
        <div className='container orgainising-logo'>
            <h2 className='logo-section-title'>Organising Partners</h2>
            <div className='logo-group'>
            <img src = {lsdpc} alt="" className="logo-item" />
            <img src = {dhq} alt="" className="logo-item" />
            <img src = {market} alt="" className="logo-item" />
            </div>

            <h2 className='logo-section-title'>Participating Brands</h2>
            <div className='logo-group'>
            <img src = {homework} alt="" className="logo-item" />
            <img src = {mixta} alt="" className="logo-item" />
            <img src = {fha} alt="" className="logo-item" />
            <img src = {fh} alt="" className="logo-item" />
            </div>
            
        </div>
     );
}
 
export default OrganisingPartner;