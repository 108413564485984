import { Link } from 'react-router-dom';
import repsbanner from '../images/reps-banner.jpg'

const RepsBanner = () => {
    return (
        <Link to='https://reps2023.thinkmint.ng' className="vote-banner">
            <img src={repsbanner} alt="" className="img-fluid" />
        </Link>
      );
}
 
export default RepsBanner;