import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import redalogo from '../images/reda-logo.png'

const NavBar = () => {
    return ( 
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
            <img
            alt='main logo'
            src = {redalogo}
            width="120"
            // height="30"
            className='d-inline-block align-top link-logo'
            />
            </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#highlight2023">About</Nav.Link>
            <Nav.Link href="#subtheme">Subtheme</Nav.Link>
            <Nav.Link href="#speakers">Speakers</Nav.Link>
            <Nav.Link href="#topics">Topics</Nav.Link>
            <Nav.Link href="https://drive.google.com/file/d/1sjzdLihqgEKycoCQ8eLCWcwUDujIY15j/view?usp=sharing">2022 PostEvent</Nav.Link>
            <NavDropdown title="Register" id="basic-nav-dropdown">
              <NavDropdown.Item href="#forms">Attend conference</NavDropdown.Item>
              <NavDropdown.Item href="https://mailchi.mp/thinkmint/reda2023">
              Free exhibition pass
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
     );
}
 
export default NavBar;